import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  tableTimes: []
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  LogOut(state){
    state.user = null
    state.role = null
  },
  UPDATE_TABLE(state, data) {
    //state.table.data = data; // replace `table` with your own table name
    // state.tableTimes.length = 0;
    state.tableTimes = data; // replace `table` with your own table name
  }
}

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    auth
  },
  plugins: [createPersistedState()]
})