import Vue from 'vue'
import Router from 'vue-router'
import store from "../store";
// import Grses from "../views/grs/Grses";

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Login = () => import('@/views/pages/Login')

const Regions = () => import('@/views/regions/Regions')
const Grses = () => import('@/views/grs/Grses')
const Grs = () => import('@/views/grs/Grs')
const Lines = () => import('@/views/lines/Lines')
const Line_s = () => import('@/views/lines/Line')
const Operators = () => import('@/views/operators/Operators')
const Operator = () => import('@/views/operators/Operator')
const Times = () => import('@/views/times/Times')
const Datatypes = () => import('@/views/datatypes/Datatypes')
const Inputdata = () => import('@/views/inputdata/Inputdata')
const Results_22 = () => import('@/views/results_22/Results_22_2')
const Results = () => import('@/views/results/Results')
const Monitor = () => import('@/views/results/Monitor_4')
const Monitor_5 = () => import('@/views/results/Monitor_5')
const Map = () => import('@/views/map/Map')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/monitor',
      name: 'Главная',
      component: TheContainer,
      meta: {
        requiresAuth: true,
        roleAccess: "2"
      },
      children: [
        {
          path: '/lines',
          name: 'Нитки',
          redirect: '/lines',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            requiresAuth: true,
            roleAccess: "4"
          },
          children: [
            {
              path: '',
              name: 'Список ниток',
              component: Lines,
              meta: {
                requiresAuth: true,
                roleAccess: "4"
              },
            },
            {
              path: ':id',
              name: 'Line_s',
              component: Line_s,
              meta: {
                requiresAuth: true,
                roleAccess: "4"
              },
            }
          ]
        },
        {
          path: '/operators',
          name: 'Операторы',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            requiresAuth: true,
            roleAccess: "4"
          },
          children: [
            {
              path: '',
              name: 'Список операторов',
              component: Operators,
              meta: {
                requiresAuth: true,
                roleAccess: "4"
              }
            },
            {
              path: ':id',
              name: 'Список операторов',
              component: Operator,
              meta: {
                requiresAuth: true,
                roleAccess: "4"
              }
            }
          ]
        },
        {
          path: '/grses',
          name: 'ГРС',
          redirect: '/grses/list',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            requiresAuth: true,
            roleAccess: "4"
          },
          children: [
            {
              path: '',
              name: 'Список ГРС',
              component: Grses,
              meta: {
                requiresAuth: true,
                roleAccess: "4"
              }
            },
            {
              path: ':id',
              name: 'Grs',
              component: Grs,
              meta: {
                requiresAuth: true,
                roleAccess: "4"
              }
            }
          ]
        },
        {
          path: '/inputdata',
          name: 'Ручной ввод',
          redirect: '/inputdata/form',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            requiresAuth: true,
            roleAccess: "4"
          },
          children: [
            {
              path: '',
              name: 'Ручной ввод',
              component: Inputdata,
              meta: {
                requiresAuth: true,
                roleAccess: "4"
              }
            },
          ]
        },
        {
          path: '/results_22',
          name: 'Отчет 22',
          redirect: '/results_22',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            requiresAuth: true,
            roleAccess: "3"
          },
          children: [
            {
              path: '',
              name: 'Таблица',
              component: Results_22,
              meta: {
                requiresAuth: true,
                roleAccess: "3"
              }
            },
          ]
        },
        {
          path: '/results',
          name: 'Отчет',
          redirect: '/results/form',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            requiresAuth: true,
            roleAccess: "2"
          },
          children: [
            {
              path: '',
              name: 'Отчет',
              component: Results,
              meta: {
                requiresAuth: true,
                roleAccess: "2"
              }
            },
          ]
        },
        {
          path: '/monitor',
          name: 'Монитор',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            requiresAuth: true,
            roleAccess: "2"
          },
          children: [
            {
              path: '',
              name: 'Таблица',
              component: Monitor,
              meta: {
                requiresAuth: true,
                roleAccess: "2"
              }
            },
          ]
        },
        {
          path: '/zich',
          name: 'Монитор',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            requiresAuth: true,
            roleAccess: "2"
          },
          children: [
            {
              path: '',
              name: 'Таблица',
              component: Monitor_5,
              meta: {
                requiresAuth: true,
                roleAccess: "2"
              }
            },
          ]
        },
        {
          path: '/users',
          name: 'Пользователи',
          component: {
            render(c) {
              return c('router-view')
            }
          },
          meta: {
            requiresAuth: true,
            roleAccess: "4"
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users,
              meta: {
                requiresAuth: true,
                roleAccess: "4"
              },
            },
            {
              path: ':id',
              name: 'User',
              component: User,
              meta: {
                requiresAuth: true,
                roleAccess: "4"
              },
            }
          ]
        },
      ]
    },
    {
      path: '/regions',
      name: 'Области',
      redirect: '/regions/list',
      component: TheContainer,
      children: [
        {
          path: 'list',
          name: 'Список областей',
          component: Regions,
          meta: {
            requiresAuth: true,
            roleAccess: "4"
          }
        }
      ],
      meta: {
          requiresAuth: true,
          roleAccess: "4"
      }
    },
    {
      path: '/map',
      name: 'Карта',
      redirect: '/map/view',
      component: TheContainer,
      children: [
        {
          path: 'view',
          name: 'Карта',
          component: Map,
          meta: {
            requiresAuth: true,
            roleAccess: "3"
          }
        }
      ],
      meta: {
        requiresAuth: true,
        roleAccess: "3"
      }
    },
    {
      path: '/times',
      name: 'Режимы',
      redirect: '/times/list',
      component: TheContainer,
          meta: {
            requiresAuth: true,
            roleAccess: "4"
          },
      children: [
        {
          path: 'list',
          name: 'Список режимов',
          component: Times,
          meta: {
            requiresAuth: true,
            roleAccess: "4"
          }
        }
      ]
    },
    {
      name: 'Типы данных',
      path: '/datatypes',
      redirect: '/datatypes/list',
      component: TheContainer,
          meta: {
            requiresAuth: true,
            roleAccess: "4"
          },
      children: [
        {
          path: 'list',
          name: 'Список типов данных',
          component: Datatypes,
          meta: {
            requiresAuth: true,
            roleAccess: "4"
          }
        }
      ]
    },
    {
      path: '/login',
      name: 'Вход',
      component: Login
    }
  ]
}

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated && parseFloat(store.getters.userRole) >= parseFloat(to.meta.roleAccess)) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/monitor");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
