import 'core-js/stable'
import "regenerator-runtime/runtime";
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { ClientTable } from "vue-tables-2"
import store from './store';
import axios from 'axios';
import {icons} from "./assets/icons/icons";

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://kalish.uz/cms_local.php';


let options = {};
let useVuex = false;
let theme = "bootstrap4";
let template = "default";
Vue.use(ClientTable, options, useVuex, theme, template);

Vue.config.productionTip = false;

// new Vue({
//   el: '#app',
//   router,
//   store,
//   icons,
//   template: '<App/>',
//   components: {
//     App
//   }
// })


Vue.config.productionTip = false
new Vue({
  store,
  router,
  icons,
  render: h => h(App)
}).$mount('#app')
