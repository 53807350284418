//store/modules/auth.js

import axios from 'axios';
const state = {
    user: null,
    role: null,
};
const getters = {
    isAuthenticated: state => !!state.user,
    StateUser: state => state.user,
    userRole: state => state.role
};
const actions = {
    async LogIn({commit}, user) {
        await commit("setUser", user);
    },
    async LogOut({commit}){
      let user = null
      commit('LogOut', user)
    }
};
const mutations = {
    setUser(state, user){
        state.user = user.login
        state.role =  user.role
    },
    LogOut(state){
      state.user = null
      state.role = null
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};
